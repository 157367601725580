.pageIdentify {
    max-width: 100%;
    padding: 20px;
    background-color: #141d26ed;
    position: sticky;
    top: 0px;
    color: white;
    font-weight: bold;
    font-size: 20px;
    z-index: 999;
}

.mainContent {}

.profilePost {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #718096;
    padding: 20px;
}

.postBox {
    width: 89%;
}

.profilePic {
    border-radius: 50%;
    width: 50px;
    height: 50px;
}

.imgOrPost {
    display: flex;
    justify-content: space-between;
    width: 95%;
    align-items: center;
}

.post {
    background-color: #1DA1F2;
    padding: 10px 20px;
    border-radius: 1000px;
    color: white;
    font-weight: 600;
    display: flex;
    justify-content: center;
    gap: 5px;
}

.post:hover {
    cursor: pointer;
}

.postOptions {
    display: flex;
    justify-content: end;
    gap: 10px;
}

.imgDiv {
    border-radius: 50%;
    padding: 10px;
}

.imgDiv:hover {
    cursor: pointer;
    background-color: #282828;
}

.postImg {
    width: 98%;
    border-radius: 10px;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}