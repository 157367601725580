.feedPost {
    max-width: 100%;
    border-bottom: 1px solid #718096;
    padding: 20px;
    color: white;
    justify-content: space-between;
    display: flex;
}

.feedPost:hover {
    background-color: #1c2834;
}

.completePost {
    display: flex;
    justify-content: start;
    flex-direction: column;
    gap:10px;
    width:89%;
    gap:10px;
}

.interactions {
    margin-top: 10px;
    display: flex;
    justify-content: start;
    gap: 150px;
}

.interactionNums {
    color:#e0e0e0;
    display: flex;
    justify-content: start;
    gap: 10px;
    padding: 10px;
    border-radius: 1000px;
}

.interactionNums:hover {
    cursor:pointer;
    background-color: #1da0f20b;
}

.who {
    display: flex;
    gap: 10px;
    font-weight: 600;
    align-items: center;
}

.accWhen {
    font-size: 15px;
    font-weight: 400;
    color: gray;
}

.postContent{
    display: flex;
    justify-content: start;
    flex-direction: column;
    gap:10px;
}

.postImg {
    width: 50%;
    border-radius: 10px;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
